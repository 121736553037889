<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :items="recordings"
          :headers="headers"
          hide-default-footer
        >
          <template v-slot:item.numbers="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:item.title="{ item }">
            <div>
              <a
                href="javascript:void(0)"
                class="font-weight-bold text-decoration-none"
                @click="viewDetails(item)"
              >
                {{ item.title }}
              </a>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="editDialog(item)" class="primary--text mr-1">
              mdi-text-box-edit-outline
            </v-icon>
            <v-icon @click="deleteItem(item)" class="error--text">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <div class="float-start" v-if="pagination.total > 1">
          <v-pagination
            v-model="pagination.page"
            :total-visible="7"
            :length="pagination.total"
            @input="nextPage"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <edit-recording-dialog ref="editDialog"></edit-recording-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import EditRecordingDialog from "../../components/EditRecordingDialog";
export default {
  name: "MyRecording",
  components: {
    EditRecordingDialog,
  },
  data() {
    return {
      headers: [
        { text: "#", value: "numbers", orderable: false, sortable: false },
        { text: "Name", value: "title" },
        { text: "Recorded By", value: "user.name" },
        { text: "category", value: "category.name" },
        { text: "Recorded", value: "date_recorded" },
        { text: "Actions", value: "actions" },
      ],
      recordings: [],
      pagination: {
        page: 1,
        total: 1,
      },
      search: "",
      timer: 0,
      timerId: null,
    };
  },
  created() {
    this.myRecordings();
    this.$root.$on("search", (data) => {
      this.search = data;
      this.pagination.page = 1;
      this.myRecordings();
    });
  },
  methods: {
    ...mapMutations("recordings", ["SHOW_DETAILS"]),
    ...mapActions("recordings", ["my_recordings", "delete_recording"]),
    ...mapMutations("auth", ["LOADING"]),
    async myRecordings(query) {
      if (!query) {
        query = "?page=" + this.pagination.page + "&search=" + this.search;
      }
      this.LOADING(true);
      try {
        const response = await this.my_recordings(query);
        this.recordings = response.data.data;
        this.LOADING(false);
      } catch (e) {
        this.LOADING(false);
        this.$response.sendError(e);
      }
    },
    async deleteItem(item) {
      let confirmDelete = confirm("This action is not reversible...");
      if (confirmDelete) {
        this.LOADING(true);
        try {
          const response = await this.delete_recording(item);
          this.nextPage();
          this.$response.sendSuccess(response);
          this.LOADING(false);
        } catch (e) {
          this.LOADING(false);
          this.$response.sendError(e);
        }
      }
    },
    nextPage() {
      let query = "";
      let page = this.pagination.page;
      let search = this.search;
      if (search !== "") {
        query = "?page=" + page + "&search=" + search;
      }
      query = "?page=" + page;
      this.myRecordings(query);
    },
    viewDetails(item) {
      this.SHOW_DETAILS(item);
      this.$router.push({
        name: "MyRecordingDetails",
        params: { id: item.id },
      });
    },
    editDialog(item) {
      this.$refs.editDialog.openDialog(item);
    },
  },
};
</script>

<style scoped></style>
